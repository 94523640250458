<script>
export default {
  name: "SchoolAddForm",
  props: {
    schoolAddFormVisible: Boolean
  }, methods: {
    schoolAddFormVisibleClick() {
      this.$emit('update:schoolAddFormVisible', false)
    }
  }
};
</script>

<template>
  <el-card class="full-width-card">
    <template #header>
      <div class="card-header">
        <span>Добавить школу</span>
      </div>
    </template>

    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="schoolAddFormVisibleClick">Отмена</el-button>

        <el-button type="primary">Добавить школу</el-button>
      </el-row>
    </template>

  </el-card>


</template>

<style scoped>

</style>