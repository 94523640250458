<script>
import SchoolList from '../components/SchoolList.vue';


export default {
  name: "HomePage",
  data() {
    return {};
  },
  components: {
    SchoolList
  }
};
</script>

<template>
  <SchoolList/>
</template>

<style scoped>

</style>