<script>
export default {
  name: "SchoolListSearchOrAdd",
  props: {
    schoolAddFormVisible: Boolean
  },
  data() {
    return {
      SchoolSearch: ''
    }
  },

  methods: {
    schoolAddFormVisibleClick() {
      this.$emit('update:schoolAddFormVisible', true)
    }
  }
}
</script>

<template>
  <el-col :span="9">
    <el-input
        v-model="SchoolSearch"
        style="width: 240px"
        placeholder="Найти"
        :prefix-icon="Search"
    />
  </el-col>
  <el-col :span="2">
    <el-text class="mx-1" type="primary" size="large">или</el-text>
  </el-col>
  <el-col :span="11">
    <el-button style="width: 240px" type="primary" @click="schoolAddFormVisibleClick">Добавить школу</el-button>
  </el-col>
</template>

<style scoped>

</style>