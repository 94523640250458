<script>
export default {
  name: "LibraryPage"
};
</script>

<template>
  <div>
    Библиотека
  </div>
</template>

<style scoped>

</style>