<!-- src/components/LoginForm.vue -->
<template>
  <div class="login-form">
    <el-button type="primary" @click="startOAuth">Войти через VK.ID</el-button>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    apiUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    async startOAuth() {
      try {
        const protocol = window.location.protocol
        const url = window.location.host
        const response = await fetch(`${this.$apiUrl}auth/?url=${encodeURIComponent(protocol + '|' + url)}`, {
          method: 'GET',
        })
        const data = await response.json()
        if (data.link) {
          console.log(data.link)
          window.location.href = data.link
        } else {
          console.error('No OAuth URL returned from API')
        }
      } catch (error) {
        console.error('Error starting OAuth', error)
      }
    }
  }
}
</script>

<style scoped>
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
