<script>
export default {
  name: "SchoolListElement",
  props: {
    school: {
      type: Object
    }
  },
  data() {
    return {
      school_data: {}
    }
  },
  methods: {
    async fetchSchool() {
      const school_id = this.school.school_id
      this.school_data = await this.$api_request("GET", `schools/school/${school_id}/`)
    },
    async schoolSelect(id, name) {
      this.$globalState.schoolId = id
      this.$globalState.schoolName = name
      //тут затычка, нужно добавить запрос к api что бы получать активные модули для школы
      this.$globalState.schoolModuleLibrary = true
    }
  },
  created() {
    this.fetchSchool()
  }
}
</script>

<template>
  <div class="schoolElement" @click="schoolSelect(school_data.id, school_data.name)">
    <div class="schoolDetails">
      <div class="schoolName">{{ school_data.name }}</div>
      <div class="schoolAddress">{{ school_data.address }}</div>
      <div class="schoolINN">ИНН: {{ school_data.inn }}</div>
    </div>
    <div class="schoolContact">
      <div class="schoolPhone">{{ school_data.phone }}</div>
      <div class="schoolEmail">{{ school_data.email }}</div>
    </div>
  </div>
</template>

<style scoped>
.schoolElement {
  display: flex;
  justify-content: space-between; /* Распределяем содержимое по горизонтали */
  align-items: flex-start; /* Выравниваем элементы по верхнему краю */
  background-color: #f9f9f9; /* Светлый фон */
  border: 1px solid #ddd; /* Светлая рамка */
  border-radius: 8px; /* Скругленные углы */
  padding: 20px; /* Внутренние отступы */
  margin-bottom: 15px; /* Отступ снизу между элементами */
  cursor: pointer;
}

.schoolDetails {
  display: flex;
  flex-direction: column; /* Выравнивание элементов по вертикали */
  flex: 2; /* Занимает основное пространство */
}

.schoolContact {
  display: flex;
  flex-direction: column; /* Выравнивание элементов по вертикали */
  align-items: flex-end; /* Выравнивание элементов по правому краю */
  flex: 1; /* Занимает меньше пространства */
}

.schoolName {
  font-size: 1.2em; /* Увеличенный размер шрифта для имени */
  font-weight: bold; /* Жирное начертание */
  color: #333; /* Темный цвет текста */
  margin-bottom: 10px; /* Отступ снизу */
}

.schoolAddress,
.schoolINN {
  color: #666; /* Средний серый цвет текста */
  margin-bottom: 5px; /* Отступ снизу */
}

.schoolPhone,
.schoolEmail {
  color: #444; /* Чуть светлее цвет текста */
  margin-bottom: 5px; /* Отступ снизу */
}
</style>
