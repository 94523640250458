<script>
import HelloUser from '@/components/HelloUser.vue';
import LoginForm from '@/components/LoginForm.vue';
import MainMenu from '@/components/MainMenu.vue';
import MainFooter from "@/components/MainFooter.vue";

export default {
  name: 'App',
  components: {
    HelloUser,
    LoginForm,
    MainMenu,
    MainFooter
  },
  data() {
    return {
      finRender: false,
      isAuthenticated: false,
      user_first_name: "",
      user_last_name: "",
      welcomeMessage: 'Добро пожаловать в центр управления школой'
    };
  },
  created() {
    this.checkUser();
  },
  methods: {
    async checkUser() {
      const data = await this.$api_request("GET", 'users/me')
      if (data.status) {
        this.isAuthenticated = data.status
        this.user_last_name = data.user_data.last_name
        this.user_first_name = data.user_data.first_name
        this.finRender = true
      } else {
        // await this.$api_request("GET", 'users/me')
        this.finRender = true
      }

    }
  }
};
</script>

<template>
  <el-col class="common-layout" v-if="finRender">
    <el-container class="full-height">
      <el-header>
        <div v-if="isAuthenticated" class="apps">
          <MainMenu :user_first_name="user_first_name" :user_last_name="user_last_name"/>
        </div>
      </el-header>
      <el-main class="flex-grow">
        <el-row v-if="!isAuthenticated">
          <el-col :span="18">
            <HelloUser :msg="welcomeMessage"/>
          </el-col>
          <el-col :span="6">
            <LoginForm :apiUrl="apiUrl"/>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" v-if="isAuthenticated">
            <router-view class="router-view"></router-view>
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <MainFooter/>
      </el-footer>
    </el-container>
  </el-col>
</template>

<style scoped>
/* Заставляем контейнер занимать всю высоту экрана */
.full-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Основной контент растягивается */
.flex-grow {
  flex-grow: 1;
}
</style>
