<script>
export default {
  name: "MainFooter"
};
</script>

<template>
  <div>Footer</div>
</template>

<style scoped>

</style>