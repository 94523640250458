import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../views/HomePage.vue';
import LibraryPage from '../views/LibraryPage.vue';

const routes = [
    {
        path: '/',
        component: HomePage,
        meta: {title: 'Школа'}
    },{
        path: '/library',
        component: LibraryPage,
        meta: {title: 'Библиотека'}
    },

];

const router = createRouter({
    history: createWebHistory(), // Использует HTML5 History API
    routes,
});

router.afterEach((to) => {
    document.title = to.meta.title || 'Центр управления школой';
});

export default router;
