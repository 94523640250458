<script>
import SchoolListElement from '@/components/SchoolListElement.vue'
import SchoolListAdd from "@/components/SchoolListAdd.vue";

export default {
  name: "SchoolList",
  props: {},
  data() {
    return {
      schools: []
    }
  },
  components: {
    SchoolListElement,
    SchoolListAdd
  },
  methods: {
    async getUserSchools() {
      this.schools = await this.$api_request("GET", "users/user_school");
    }
  },
  created() {
    this.getUserSchools();
  }
}

</script>

<template>
  <el-row>
    <el-col :span="12" :offset="6" v-if="schools.length === 0">
      <SchoolListAdd/>
    </el-col>
    <el-col :span="12" :offset="6" v-else>
      <div v-for="school in schools" :key="school.id">
        <SchoolListElement :school="school"/>
      </div>
    </el-col>
  </el-row>
</template>

<style scoped>

</style>