<template>
  <nav>
    <el-row type="flex" justify="space-between" align="middle">
      <!-- Левое меню -->
      <el-col :span="4">
        <el-menu :default-active="$route.path" mode="horizontal">
          <el-menu-item v-if="!$globalState.schoolId">
            <router-link to="/">Выбор школы</router-link>
          </el-menu-item>
          <el-menu-item v-if="$globalState.schoolId">
            <router-link to="/">{{ $globalState.schoolName }}</router-link>
          </el-menu-item>
          <el-menu-item v-if="$globalState.schoolModulePlaner">
            <router-link to="#">Расписание</router-link>
          </el-menu-item>
          <el-menu-item v-if="$globalState.schoolModuleAlert">
            <router-link to="#">Оповещения</router-link>
          </el-menu-item>
          <el-menu-item v-if="$globalState.schoolModuleLibrary">
            <router-link to="/library">Библиотека</router-link>
          </el-menu-item>
        </el-menu>
      </el-col>

      <!-- Поиск по центру -->
      <el-col :span="5" class="menu-item-center">
        <el-input placeholder="Search..."/>
      </el-col>

      <!-- Информация о пользователе справа -->
      <el-col :span="4" class="menu-item-right">
        <el-text style="padding: 20px">{{ user_first_name }} {{ user_last_name }}</el-text>
        <el-button style="padding: 20px 10px" @click="logout">
          <el-icon size="24">
            <SwitchButton/>
          </el-icon>
        </el-button>
      </el-col>
    </el-row>
  </nav>
</template>


<style>


</style>

<script>
export default {
  name: 'MainMenu',
  props: {
    user_first_name: {
      type: String,
      default: ''
    },
    user_last_name: {
      type: String,
      default: ''
    }
  },
  methods: {
    async logout() {
      const result = await this.$api_request('GET', 'auth/logout')
      this.$globalState.schoolId = null
      this.$globalState.schoolName = null
      this.$globalState.user = null
      if (!result.auth) {
        window.location.reload();
      }
    }
  }
}
</script>