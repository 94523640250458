import {createApp} from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue';
import router from './router/index.js';
import axios from "axios";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);
import {reactive} from 'vue';

// тут я отключил варнинги в консоль, задолбали
app.config.warnHandler = function () {
    return null
}


app.config.globalProperties.$globalState = reactive({
    schoolId: null,
    schoolName: null,
    schoolModulePlaner: null,
    schoolModuleAlert: null,
    schoolModuleLibrary: null
});

// Получаем текущий хост
const currentHost = window.location.host;

// Определяем URL API в зависимости от хоста
let apiUrl;
if (currentHost === 'localhost:8080') {
    apiUrl = 'http://localhost/';
} else {
    apiUrl = 'https://api.educontrolhub.ru/';
}

// Подставляем URL в глобальные свойства
app.config.globalProperties.$apiUrl = apiUrl;

axios.defaults.maxRedirects = 5

app.config.globalProperties.$api_request = async function (method, url, data = {}) {
    try {
        const response = await axios({
            method: method,
            url: this.$apiUrl + url,
            data: data,
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        console.log("data from api request: ", response.data);
        return response.data;
    } catch (error) {
        let message = 'Unknown error';
        if (error.response) {
            message = error.response.data.detail?.message || 'Server error';
            console.log(message);
            if (message === "Token expired") {
                await this.$refresh_token(method, url, data);
            }
            if (message === "Invalid token") {
                this.isAuthenticated = false;
            }
        } else {
            console.log('Network error or no response from server');
            console.log(error);
        }
        return {status: false, error: message};
    }
}

app.config.globalProperties.$refresh_token = async function (method, url, data = {}) {
    await this.$api_request("GET", 'auth/refresh')
    return this.$api_request(method, url, data)
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router);
app.use(ElementPlus);
app.mount('#app');