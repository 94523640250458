<template>
  <div class="hellouser">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'HelloUser',
  props: {
    msg: {
      type: String,
      default: 'Добро пожаловать в центр управления школой'
    }
  }
}
</script>

<style scoped>
.hellouser {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

h1 {
  margin: 40px 0 0;
}
</style>
