<script>
import SchoolAddForm from "@/components/SchoolAddForm.vue";
import SchoolListSearchOrAdd from "@/components/SchoolListSearchOrAdd.vue";

export default {
  name: "SchoolListAdd",
  data() {
    return {
      schoolAddFormVisible: false
    };
  },
  methods: {
    handleSchoolAddFormVisibleUpdate(newValue) {
      this.schoolAddFormVisible = newValue;
    }
  },
  components: {
    SchoolAddForm,
    SchoolListSearchOrAdd
  }
};
</script>

<template>
  <el-row :gutter="20" v-if="!schoolAddFormVisible">
    <el-col :span="12" :offset="6">
      <el-text class="mx-1" type="primary" size="large">Вы еще не привязаны к школе</el-text>
    </el-col>
  </el-row>
  <el-row :gutter="20" v-if="!schoolAddFormVisible" justify="space-around">
    <SchoolListSearchOrAdd
        :schoolAddFormVisible="schoolAddFormVisible"
        @update:schoolAddFormVisible="handleSchoolAddFormVisibleUpdate" />
  </el-row>
  <el-row v-if="schoolAddFormVisible">
    <el-col :span="24">
      <SchoolAddForm
          :schoolAddFormVisible="schoolAddFormVisible"
          @update:schoolAddFormVisible="handleSchoolAddFormVisibleUpdate" />
    </el-col>

  </el-row>

</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}
</style>